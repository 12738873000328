import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const ListingDetailsContext = createContext();

// Define the baseUrl for different environments
const baseUrl = (() => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'http://localhost:5000/api';
  } else if (hostname === 'www.aigentTechnologies.com') {
    return 'https://www.aigentTechnologies.com/api';
  } else if (hostname === 'www.openhouseaigent.com') {
    return 'https://www.openhouseaigent.com/api';
  } else {
    return 'https://hbb-zzz.azurewebsites.net/api'; // Default URL if no match
  }
})();

// Default listing details (for testing)
const defaultListingDetails = [
  {
    listing_agent_name: 'John Doe',
    listing_description: 'A beautiful 4-bedroom home with modern amenities and a spacious garden.',
    listingImage: 'https://via.placeholder.com/600x400?text=Listing+Image',
    logoImageOne: 'https://via.placeholder.com/200x100?text=Logo+One',
    logoImageTwo: 'https://via.placeholder.com/200x100?text=Logo+Two',
    listing_address: '19 Woekel Terrace, Methuen, MA 01844',
  }
];

// ListingDetailsProvider component that provides listing details to its children
export const ListingDetailsProvider = ({ children, listingId }) => {
  const [listingDetails, setListingDetails] = useState(defaultListingDetails); // Use default values initially
  const [loading, setLoading] = useState(false);  // Initially set loading to false for testing
  const [error, setError] = useState(null);

  // Fetch listing details from the backend (Flask API)
  useEffect(() => {
    const fetchListingDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/get_listing_details?listing_id=${listingId}`);
        const data = await response.json();
        if (response.ok) {
          setListingDetails(data);  // Update with fetched details
        } else {
          setError(data.error || 'Failed to fetch listing details');
        }
      } catch (err) {
        setError('Failed to fetch listing details');
      } finally {
        setLoading(false);
      }
    };

    if (listingId) {
      fetchListingDetails();
    }
  }, [listingId]);

  return (
    <ListingDetailsContext.Provider value={{ listingDetails, loading, error }}>
      {children}
    </ListingDetailsContext.Provider>
  );
};
