import React from 'react';
import './components/chartSetup';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { MortgageProvider } from './contexts/MortgageContext';
import { ChatProvider } from './contexts/ChatContext';
import { QuestionProvider } from './contexts/questionContext';
import { SiteCarouselProvider } from './contexts/SiteCarouselContext';
import { BinQGameProvider } from './contexts/BinQGameContext'; // Add BinQGameProvider
import { ListingDetailsProvider } from './contexts/ListingDetailsContext';
import QuestionGameContainer from './components/QuestionGameContainer';
import RenovationComponent from './components/renovationComponent';
import WelcomePage from './components/WelcomePage';
import UnderConstruction from './components/UnderConstruction';
import MortgageCalculator from './components/MortgageCalculator';
import WrapperWithContactAndBot from './components/WrapperWithContactAndBot';
import WrapperWithCarouselAndBinImageQGame from './components/WrapperWithCarouselAndBinImageQGame';
import AigentWBinImageQGame from './components/Aigent_with_BinImageQGame';
import SummaryView from './components/SummaryView';
import WrapperMainSiteCarousel from './components/WrapperMainSiteCarousel';
import RenderImageBubbleGame from './components/RenderImageBubbleGame';
import WrapperWithAgentAndRenderNeighborhoodMap from './components/WrapperWithAgentAndRenderNeighborhoodMap';
import WrapperWithAgentSummary from './components/WrapperWithAgentSummary';




//import MainCarouselWrapper from './components/MainCarouselWrapper';
//import Aigent from './components/Aigent';
//import AigentWCarousel from './components/Aigent_with_carousel';
//import ImageCarousel from './components/imageCarousel';


import './styles/hbb_global.css';
import './App.css';

// Importing QueryClient and QueryClientProvider from react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Initialize Query Client
const queryClient = new QueryClient();

function App() {
  const listing_id = '2b78c611-af06-4449-a5b6-fb2d433faf8b';

	
	
  return (
    // Wrap the entire app with QueryClientProvider
    <QueryClientProvider client={queryClient}>
      <SiteCarouselProvider>
	    <BinQGameProvider>
         <ListingDetailsProvider>
           <Router>
             <ChatProvider>
                <AuthProvider>
                  <QuestionProvider questionType="entry">
                     <MortgageProvider>
                       <Routes>
                        <Route path="/" element={<WelcomePage />} />
                        <Route path="/WelcomePage" element={<WelcomePage />} />
                        <Route path="/under-construction" element={<RenderImageBubbleGame />} />
                        <Route path="/MortgageCalculator" element={<MortgageCalculator />} />
                        <Route path="/AigentWBinImageQGame" element={<AigentWBinImageQGame />} />
                        <Route path="/RenovationComponent" element={<RenovationComponent />} />
                        <Route path="/SummaryView" element={<SummaryView />} />
                        <Route path="/WrapperWithCarouselAndBinImageQGame" element={<WrapperWithCarouselAndBinImageQGame />} />
                        <Route path="/WrapperWithAgentAndRenderNeighborhoodMap" element={<WrapperWithAgentAndRenderNeighborhoodMap />} />
                        <Route path="/WrapperWithContactAndBot" element={<WrapperWithContactAndBot />} />
                        <Route path="/WrapperMainSiteCarousel" element={<WrapperMainSiteCarousel />} />
                        <Route path="/WrapperWithAgentSummary" element={<WrapperWithAgentSummary />} />
                        <Route path="/QuestionGameContainer" element={<QuestionGameContainer />} />
                        <Route path="*" element={<WelcomePage />} />
	  
                      </Routes>
                    </MortgageProvider>
                  </QuestionProvider>
                </AuthProvider>
              </ChatProvider>
            </Router>
         </ListingDetailsProvider>
        </BinQGameProvider>
      </SiteCarouselProvider>
    </QueryClientProvider>
  );
}

export default App;
