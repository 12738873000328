import React, { createContext, useState, useContext } from 'react';

// Create Context
export const ChatContext = createContext();

// Custom hook to use the chat context
export const useChat = () => useContext(ChatContext);

// Provider Component
export const ChatProvider = ({ children }) => {
  const [context_systemPrompt, context_setSystemPrompt] = useState("");
  const [context_userPrompt, context_setUserPrompt] = useState("");
  const [context_showUser, context_setShowUser] = useState(true);
  const [context_showAgent, context_setShowAgent] = useState(true);
  const [context_chatLog, context_setChatLog] = useState({});
  const [context_questionId, context_setQuestionId] = useState("");
  const [context_siteLocation, context_setSiteLocation] = useState("default");
  const [context_chatId, context_setChatId] = useState("");
  const [context_context, context_setContext] = useState("");
  const [context_listing_id, context_set_listing_id] = useState("");  // New state for listing ID

  // Provide the state and functions to the context consumers
  return (
    <ChatContext.Provider value={{
      context_systemPrompt,
      context_setSystemPrompt,
      context_userPrompt,
      context_setUserPrompt,
      context_showUser,
      context_setShowUser,
      context_showAgent,
      context_setShowAgent,
      context_chatLog,
      context_setChatLog,
      context_siteLocation,
      context_setSiteLocation,
      context_questionId,
      context_setQuestionId,
      context_chatId,
      context_setChatId,
      context_context, 
      context_setContext,
      context_listing_id,          // Provide listing_id
      context_set_listing_id       // Provide setter for listing_id
    }}>
      {children}
    </ChatContext.Provider>
  );
};
