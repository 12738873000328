import React, { useState, useEffect } from 'react';
import Aigent_2 from './Aigent_2';
import RenderBinImageQGame from './RenderBinImageQGame';
import styles from '../styles/WrapperWithCarouselAndBinImageQGame.module.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const WrapperWithCarouselAndBinImageQGame = () => {
  const [showCarousel, setShowCarousel] = useState(true);
//  const [questions, setQuestions] = useState([]);  
  const [isLoading, setIsLoading] = useState(false); 
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  // Fetch questions on mount
//  useEffect(() => {
//    const baseUrl = (() => {
//      const hostname = window.location.hostname;
//      if (hostname === 'localhost') {
//        return 'http://localhost:5000/api';
//      } else if (hostname === 'www.aigentTechnologies.com') {
//        return 'https://www.aigentTechnologies.com/api';
//      } else if (hostname === 'www.openhouseaigent.com') {
//        return 'https://www.openhouseaigent.com/api';
//      } else {
//        return 'https://https://hbb-zzz.azurewebsites.net/api'; // Default URL if no match
//      }
//    })();
//
//    axios.get(`${baseUrl}/get_information_questions?type=entry`)
//      .then(response => {
//		console.log('quring questions');
//        setQuestions(response.data);  
//        setIsLoading(false);  
//      })
//      .catch(error => {
//        setError('Failed to fetch questions');  // Set error message
//        setIsLoading(false);
//      });
//  }, []);

  const toggleCarousel = () => {
    setShowCarousel(!showCarousel);
  };

  const collapseCarousel = () => {
    setShowCarousel(false);
  };

  const handleImageClick = (imageData, prompt = null, question = null) => {
    if (imageData.conversation_code.toLowerCase() === 'back') {
      navigate('/WrapperMainSiteCarousel');
    }
  };

  const goToCarousel = () => {
    navigate('/WrapperMainSiteCarousel');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <div className={styles.headerComponentContainer}>
          <div className={styles.header_left_container}>
            <button className={styles.returnButton} onClick={goToCarousel}>
              ←
            </button>
          </div>
          <div className={styles.header}>
            <h1 className={styles.heading}>Home Details</h1>
          </div>

          <div className={styles.header_right_container}>
            <button onClick={toggleCarousel} className={styles.toggleButton}>
              {showCarousel ? <FaChevronUp /> : <FaChevronDown />} {/* Show the appropriate icon */}
            </button>
          </div>
        </div>
      </div>

      {/* Handle loading and error states */}
      {isLoading ? (
        <div>Loading questions...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          {showCarousel && (
            <div className={styles.binQGameContainer}>
              <RenderBinImageQGame /> 
	  {/* Pass questions to component 
              <RenderBinImageQGame questions={questions} /> 
	  
	  */}
            </div>
          )}

          <div className={showCarousel ? styles.chatbotContainer : styles.chatbotContainer90}>
            <Aigent_2 maxHeight="100%" />
          </div>
        </>
      )}
    </div>
  );
};

export default WrapperWithCarouselAndBinImageQGame;
